import { useCallback, useMemo } from 'react';
import urljoin from 'url-join';

import config from '~/config';
import { getAccessToken } from '~/providers/AuthProvider';
import * as Sentry from '~/utils/sentry';
import showErrorMessage from '~/utils/showErrorMessage';

const useHttpClient = () => {
  const { apiUrl } = config;

  const http = useCallback(async ({ path, data, headers, ...options }) => {
    const token = await getAccessToken();

    const { apiUrl } = config;
    const url = urljoin(apiUrl, path);

    try {
      const response = await fetch(url, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          'Content-Type': 'application/json',
          Accepts: 'application/json',
          ...headers,
        },
        ...(data && { body: JSON.stringify(data) }),
        ...options,
      });

      return response;
    } catch (error: any) {
      Sentry.captureException(error);
      showErrorMessage(error);
    }
  }, []);

  const post = useCallback(
    async (path: string, data: any) => {
      const token = await getAccessToken();
      const url = urljoin(apiUrl, path);

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json',
            Accepts: 'application/json',
          },
          body: JSON.stringify(data),
        });

        return await response.json();
      } catch (error: any) {
        Sentry.captureException(error);
        showErrorMessage(error.message);
      }
    },
    [apiUrl]
  );

  const get = useCallback(
    async (path: string) => {
      const token = await getAccessToken();
      const url = urljoin(apiUrl, path);

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            Accepts: 'application/json',
          },
        });

        return response.json();
      } catch (error: any) {
        Sentry.captureException(error);
        showErrorMessage(error.message);
      }
    },
    [apiUrl]
  );

  return useMemo(() => ({ get, http, post }), [get, http, post]);
};

export default useHttpClient;
