import { gql, useQuery } from '@apollo/client';
import { useLinkTo } from '@react-navigation/native';
import {
  Avatar,
  FlatList,
  Heading,
  HStack,
  Pressable,
  Skeleton,
  Text,
  useColorModeValue,
  View,
  VStack,
} from 'native-base';
import React, { FC } from 'react';

import Card from '~/components/Card';
import { prependApiUrl } from '~/utils/urlUtil';

export default function TrainerFlatListContainer() {
  const bgColor = useColorModeValue('gray.50', 'dark.100');

  const linkTo = useLinkTo();

  const { loading, refetch, data, fetchMore, networkStatus } = useQuery(
    gql`
      query GetTrainers($cursor: String) {
        trainers(after: $cursor) {
          edges {
            node {
              id
              name
              photoUrl
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  // networkStatus === 2 is a setVariables event
  const flatListData = networkStatus === 2 ? [] : data?.trainers?.edges;

  return (
    <FlatList
      mt={4}
      data={flatListData}
      ItemSeparatorComponent={() => <View style={{ paddingTop: 16 }} />}
      showsVerticalScrollIndicator={false}
      keyExtractor={({ node: { id } }) => id}
      renderItem={({ item: { node } }) => {
        return <Item trainer={node} linkTo={linkTo} />;
      }}
      onRefresh={() => refetch()}
      refreshing={loading}
      ListEmptyComponent={() =>
        loading ? null : (
          <VStack space={4} justifyContent={'center'} alignItems={'center'}>
            <Text>{'No trainers'}</Text>
          </VStack>
        )
      }
      onEndReached={() => {
        if (data?.trainers?.pageInfo?.hasNextPage) {
          fetchMore({
            variables: {
              cursor: data?.trainers?.pageInfo?.endCursor,
            },
          });
        }
      }}
      onEndReachedThreshold={0}
      ListFooterComponent={() => {
        return loading ? (
          <Skeleton
            mt={flatListData?.length > 0 ? 4 : 0}
            height={20}
            // @ts-ignore
            variant={'rect'}
            bgColor={bgColor}
            borderRadius={8}
          />
        ) : null;
      }}
    />
  );
}

const Item: FC<{ trainer: any; linkTo: any }> = ({ trainer, linkTo }) => {
  return (
    <Pressable
      onPress={() =>
        // @ts-ignore
        linkTo({
          screen: 'TrainerSchedules',
          params: { trainerId: trainer.id, trainerName: trainer.name },
        })
      }
    >
      <Card
        style={{
          padding: 16,
          borderRadius: 8,
        }}
      >
        <HStack space={4} alignItems={'center'}>
          <Avatar source={trainer.photoUrl && { uri: prependApiUrl(trainer.photoUrl) }}>
            {(trainer.name as string)
              .split(' ')
              .map(n => n[0])
              .filter((_, i) => i < 2)
              .join('')
              .toUpperCase()}
          </Avatar>

          <Heading size={'md'}>{trainer.name}</Heading>
        </HStack>
      </Card>
    </Pressable>
  );
};
