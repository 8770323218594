import { gql, useQuery } from '@apollo/client';
import { useLinkTo } from '@react-navigation/native';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import {
  Button,
  FlatList,
  Heading,
  HStack,
  Pressable,
  Skeleton,
  Text,
  useColorModeValue,
  View,
  VStack,
} from 'native-base';
import React, { FC } from 'react';

import Card from '~/components/Card';
import FontAwesomeIcon from '~/components/FontAwesomeIcon';

export default function BookingFlatListContainer({ bookingStatus = 'UPCOMING' }) {
  const bgColor = useColorModeValue('gray.50', 'dark.100');

  const linkTo = useLinkTo();

  const { loading, refetch, data, fetchMore, networkStatus } = useQuery(
    gql`
      query GetBookings($status: String!, $cursor: String) {
        bookings(status: $status, after: $cursor) {
          edges {
            node {
              id
              waitingList
              schedule {
                id
                startedAt
                endedAt
                schedulableType
                gymClass {
                  id
                  name
                  trainer {
                    id
                    name
                  }
                  capacity
                }
                trainer {
                  id
                  name
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      variables: {
        status: bookingStatus,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  // networkStatus === 2 is a setVariables event
  const flatListData = networkStatus === 2 ? [] : data?.bookings?.edges;

  return (
    <FlatList
      mt={4}
      data={flatListData}
      ItemSeparatorComponent={() => <View style={{ paddingTop: 16 }} />}
      showsVerticalScrollIndicator={false}
      keyExtractor={({ node: { id } }) => id}
      renderItem={({
        item: {
          node: { waitingList, schedule },
        },
      }) => {
        return <Item waitingList={waitingList} schedule={schedule} linkTo={linkTo} />;
      }}
      onRefresh={() => refetch()}
      refreshing={loading}
      ListEmptyComponent={() =>
        loading ? null : (
          <VStack space={4} justifyContent={'center'} alignItems={'center'}>
            <Text>{'No bookings'}</Text>
            <Button onPress={() => linkTo('/timetable')}>{'Browse classes'}</Button>
          </VStack>
        )
      }
      onEndReached={() => {
        if (data?.bookings?.pageInfo?.hasNextPage) {
          fetchMore({
            variables: {
              cursor: data?.bookings?.pageInfo?.endCursor,
            },
          });
        }
      }}
      onEndReachedThreshold={0}
      ListFooterComponent={() => {
        return loading ? (
          <Skeleton
            mt={flatListData?.length > 0 ? 4 : 0}
            height={24}
            // @ts-ignore
            variant={'rect'}
            bgColor={bgColor}
            borderRadius={8}
          />
        ) : null;
      }}
    />
  );
}

const Item: FC<{ waitingList?: boolean; schedule: any; linkTo: any }> = ({
  waitingList,
  schedule,
  linkTo,
}) => {
  return (
    <Pressable
      onPress={() =>
        // @ts-ignore
        linkTo({
          screen: schedule.schedulableType === 'GymClass' ? 'ClassSchedule' : 'TrainerSchedule',
          params: { scheduleId: schedule.id },
        })
      }
    >
      <Card
        style={{
          padding: 16,
          borderRadius: 8,
        }}
      >
        <VStack space={4}>
          <VStack>
            <Heading size={'md'}>{schedule.gymClass?.name || 'PT Session'}</Heading>

            {waitingList && (
              <HStack space={2} alignItems={'center'}>
                <Text>{'Waiting List'}</Text>
              </HStack>
            )}
          </VStack>

          <VStack>
            <Text>{schedule.gymClass?.trainer?.name || schedule.trainer?.name}</Text>

            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <HStack space={3}>
                <Text>
                  {formatDuration(
                    intervalToDuration({
                      start: new Date(schedule.startedAt),
                      end: new Date(schedule.endedAt),
                    })
                  )}
                </Text>
              </HStack>

              <HStack space={4} alignItems={'center'}>
                <HStack space={2} alignItems={'center'}>
                  <FontAwesomeIcon name={'clock-o'} />
                  <Text>{`${format(new Date(schedule.startedAt), 'HH:mm')}`}</Text>
                </HStack>

                <HStack space={2} alignItems={'center'}>
                  <FontAwesomeIcon name={'calendar'} />
                  <Text>{format(new Date(schedule.startedAt), 'dd MMM yyyy')}</Text>
                </HStack>
              </HStack>
            </HStack>
          </VStack>
        </VStack>
      </Card>
    </Pressable>
  );
};
