import AsyncStorage from '@react-native-async-storage/async-storage';
import { addSeconds, differenceInSeconds, isAfter, isBefore } from 'date-fns';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import useHttpClient from '~/api/httpClient';
import config from '~/config';
import * as Sentry from '~/utils/sentry';
import showErrorMessage from '~/utils/showErrorMessage';

export interface OAuthData {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  created_at: number;
}

type SignUpParams = {
  name: string;
  phoneNumber: string;
  email: string;
  password: string;
};

type SignInParams = {
  email: string;
  password: string;
};

type SendResetPasswordInstructionsParams = {
  email: string;
};

type UpdatePasswordParams = {
  reset_password_token: string;
  password: string;
};

type CreateMemberIfNotExistsParams = {
  gymId: number;
  gymName: string;
};

type AuthContextType = {
  token: string | undefined;
  gymId: number | undefined;
  gymName: string | undefined;
  ready: boolean;
  removeToken: () => void;
  searchGymByName: (name: string) => Promise<any>;
  signUp: (params: SignUpParams) => Promise<any>;
  signIn: (params: SignInParams) => Promise<any>;
  signOut: () => Promise<any>;
  sendResetPasswordInstructions: (params: SendResetPasswordInstructionsParams) => Promise<any>;
  updatePassword: (params: UpdatePasswordParams) => Promise<any>;
  createMemberIfNotExists: (gymId: CreateMemberIfNotExistsParams) => Promise<any>;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const useAuth = () => useContext(AuthContext);

const AuthProvider: FC = ({ children }) => {
  const [oAuthData, setOAuthData] = useState<OAuthData>();
  const [token, setToken] = useState<string>();
  const [gymId, setGymId] = useState<number>();
  const [gymName, setGymName] = useState<string>();
  const [ready, setReady] = useState(false);
  const rtTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const mountedRef = useRef(false);

  const { http, post } = useHttpClient();

  const searchGymByName = useCallback(
    async (name: string) => {
      return await post('/gyms/search_name', { name });
    },
    [post]
  );

  const getGymName = useCallback(
    async (gymId: number) => {
      const response = await http({ method: 'GET', path: `/gyms/${gymId}/name` });
      if (response) {
        const data = await response.json();

        if (data?.errors) {
          Sentry.captureAnyMessage(data.errors);
          showErrorMessage(data.errors);
        } else {
          return data;
        }
      }
    },
    [http]
  );

  const signUp = useCallback(
    async ({ name, phoneNumber, email, password }: SignUpParams) => {
      const data = await post('/member_accounts', {
        member_account: { name, phone_number: phoneNumber, email, password },
      });

      if (data?.errors) {
        Sentry.captureAnyMessage(data.errors);
        showErrorMessage(data.errors);
      } else {
        return data;
      }
    },
    [post]
  );

  const signIn = useCallback(
    async ({ email, password }: SignInParams) => {
      const data = await post('/oauth/token', {
        grant_type: 'password',
        email,
        password,
        client_id: config.oAuthClientId,
      });

      if (data?.error) {
        Sentry.captureAnyMessage(data.error);
        showErrorMessage(data.error);
      } else {
        try {
          await AsyncStorage.setItem('@oauth_data', JSON.stringify(data));
        } catch (e) {
          Sentry.captureException(e);
        }

        setOAuthData(data);
        setToken(data.access_token);
        return data;
      }
    },
    [post]
  );

  const sendResetPasswordInstructions = useCallback(
    async ({ email }: SendResetPasswordInstructionsParams) => {
      const data = await post('/member_accounts/password', { member_account: { email } });

      if (data?.errors) {
        Sentry.captureAnyMessage(data.errors);
        showErrorMessage(data.errors);
        return null;
      } else {
        return data;
      }
    },
    [post]
  );

  const updatePassword = useCallback(
    async ({ reset_password_token, password }: UpdatePasswordParams) => {
      const response = await http({
        method: 'PUT',
        path: '/member_accounts/password',
        data: { member_account: { reset_password_token, password } },
      });

      if (response?.ok) {
        return true;
      } else if (response) {
        const errors = (await response.json()).errors;
        Sentry.captureAnyMessage(errors);
        showErrorMessage(errors);
      }
    },
    [http]
  );

  const signOut = useCallback(async () => {
    const response = await http({
      method: 'POST',
      path: '/oauth/revoke',
      data: {
        token,
        client_id: config.oAuthClientId,
      },
    });

    if (response?.ok) {
      await AsyncStorage.multiRemove(['@oauth_data', '@gym_id', '@gym_name']);
      setOAuthData(undefined);
      setToken(undefined);
      setGymId(undefined);
      setGymName(undefined);
      return true;
    } else if (response) {
      const errors = (await response.json()).errors;
      Sentry.captureAnyMessage(errors);
      showErrorMessage(errors);
    }
  }, [http, token]);

  const createMemberIfNotExists = useCallback(
    async ({ gymId, gymName }: CreateMemberIfNotExistsParams) => {
      const response = await http({
        method: 'POST',
        path: '/members/create_if_not_exists',
        headers: { 'X-Gym-Id': gymId },
      });

      if (response) {
        const data = await response.json();

        if (data?.errors) {
          Sentry.captureAnyMessage(data.errors);
          showErrorMessage(data.errors);
        } else {
          await AsyncStorage.multiSet([
            ['@gym_id', JSON.stringify(gymId)],
            ['@gym_name', gymName],
          ]);
          setGymId(gymId);
          setGymName(gymName);
          return data;
        }
      }
    },
    [http]
  );

  const removeToken = useCallback(async () => {
    await AsyncStorage.removeItem('@oauth_data');
    setToken(undefined);
    setOAuthData(undefined);
  }, []);

  const refreshToken = useCallback(async () => {
    const data = await AsyncStorage.getItem('@oauth_data');

    if (!data) return '';
    const oAuthData: OAuthData = JSON.parse(data);

    const response = await http({
      method: 'POST',
      path: '/oauth/token',
      data: {
        grant_type: 'refresh_token',
        refresh_token: oAuthData?.refresh_token,
        client_id: config.oAuthClientId,
      },
    });

    if (response) {
      const data = await response.json();
      if (data?.error) {
        Sentry.captureAnyMessage(data.error);
        showErrorMessage(data.error);
      } else {
        try {
          await AsyncStorage.setItem('@oauth_data', JSON.stringify(data));
        } catch (e) {
          Sentry.captureException(e);
        }

        setOAuthData(data);
        setToken(data.access_token);
        return data;
      }
    }
  }, [http]);

  useEffect(() => {
    if (!oAuthData) return;

    (async () => {
      const { created_at, expires_in } = oAuthData;
      const expiredAt = addSeconds(new Date(created_at * 1000), expires_in);

      if (isAfter(new Date(), expiredAt)) {
        await refreshToken();
      }

      const timeoutTime = (differenceInSeconds(expiredAt, new Date()) - 60) * 1000;

      rtTimeoutRef.current = setTimeout(async () => {
        mountedRef.current && (await refreshToken());
      }, timeoutTime);
    })();
  }, [oAuthData, refreshToken]);

  useEffect(() => {
    (async () => {
      const [[_tokenKey, data], [_gymIdKey, gymId], [_gymNameKey, gymName]] =
        await AsyncStorage.multiGet(['@oauth_data', '@gym_id', '@gym_name']);

      if (data) {
        try {
          const oAuthData = JSON.parse(data);
          const { access_token, created_at, expires_in } = oAuthData;
          const expiredAt = addSeconds(new Date(created_at * 1000), expires_in);

          if (isBefore(new Date(), expiredAt)) {
            setToken(access_token);
            setOAuthData(oAuthData);
          } else {
            await refreshToken();
          }

          if (gymId) {
            setGymId(Number(gymId));
          }

          if (gymName) {
            setGymName(String(gymName));
          } else if (gymId) {
            const res = await getGymName(Number(gymId));
            setGymName(res?.name);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }

      setReady(true);
    })();
  }, [getGymName, refreshToken]);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  });

  const authContext = useMemo(
    () => ({
      token,
      createMemberIfNotExists,
      gymId,
      gymName,
      ready,
      removeToken,
      searchGymByName,
      sendResetPasswordInstructions,
      signIn,
      signOut,
      signUp,
      updatePassword,
    }),
    [
      createMemberIfNotExists,
      gymId,
      gymName,
      ready,
      removeToken,
      searchGymByName,
      sendResetPasswordInstructions,
      signIn,
      signOut,
      signUp,
      token,
      updatePassword,
    ]
  );

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};

export const getAccessToken = async (): Promise<string> => {
  try {
    const data = await AsyncStorage.getItem('@oauth_data');
    if (!data) return '';
    const oAuthData: OAuthData = JSON.parse(data);

    return oAuthData?.access_token;
  } catch (error) {
    Sentry.captureException(error);
    return '';
  }
};

export default AuthProvider;
