/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          SignIn: 'sign-in',
          SignUp: 'sign-up',
          ResetPassword: 'reset-password',
          UpdatePassword: 'update-password',
          SelectGym: 'select-gym',
          Main: {
            screens: {
              Home: {
                screens: {
                  Timetable: 'timetable',
                  Membership: 'membership',
                  Activity: 'activity',
                  Profile: 'profile',
                },
              },
              CheckIn: '/check-in',
              TrainerSchedules: '/trainer-schedules',
              ClassSchedule: '/class-schedule',
              TrainerSchedule: '/trainer-schedule',
              MidtransSnap: '/midtrans-snap',
              Account: '/account',
              Transactions: '/transactions',
              AboutGym: '/about-gym',
              Settings: '/settings',
            },
          },
        },
      },
      Modal: 'modal',
      NotFound: '*',
    },
  },
};

export default linking;
