import { yupResolver } from '@hookform/resolvers/yup';
import { useLinkTo } from '@react-navigation/native';
import { Text, VStack, FormControl, Input, Button, HStack, Heading, Toast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import LinkNavigation from '~/components/LinkNavigation';
import ScreenBackground from '~/components/ScreenBackground';
import yup from '~/lib/yup';
import { useAuth } from '~/providers/AuthProvider';

const schema = yup.object().shape({
  name: yup.string().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().required().email().label('Email'),
  password: yup.string().required().min(6).label('Password'),
});

export default function AuthScreen() {
  // Prevent crashing on Xiaomi devices
  // https://github.com/facebook/react-native/issues/27204#issuecomment-902820636
  const [caretHidden, setCaretHidden] = useState(true);

  const [loading, setLoading] = useState(false);
  const { signUp } = useAuth();

  const linkTo = useLinkTo();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{
    name: string;
    phoneNumber: string;
    email: string;
    password: string;
  }>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const handleSignUp = async (values: Parameters<typeof signUp>[0]) => {
    setLoading(true);
    const res = await signUp({ ...values });

    if (res) {
      Toast.show({ title: 'Member account created successfuly', status: 'success' });
      linkTo('/sign-in');
    }
    setLoading(false);
  };

  useEffect(() => {
    setCaretHidden(false);
  }, []);

  return (
    <ScreenBackground px={'5%'} py={'10'}>
      <Heading size={'lg'} fontWeight={'600'}>
        {'Welcome'}
      </Heading>
      <Heading mt={'1'} fontWeight={'medium'} size={'xs'}>
        {'Sign up to continue!'}
      </Heading>

      <VStack space={3} mt={'5'}>
        <FormControl isInvalid={!!errors.name}>
          <FormControl.Label>{'Name'}</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input onBlur={onBlur} onChangeText={onChange} value={value} ref={ref} />
            )}
            name={'name'}
            defaultValue={''}
          />
          <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.phoneNumber}>
          <FormControl.Label>{'Phone'}</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <NumberFormat
                value={value}
                thousandsGroupStyle={'wan'}
                thousandSeparator={' '}
                allowLeadingZeros
                decimalScale={0}
                displayType={'text'}
                renderText={formattedValue => (
                  <Input
                    keyboardType={'phone-pad'}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={formattedValue}
                    ref={ref}
                  />
                )}
              />
            )}
            name={'phoneNumber'}
            defaultValue={''}
          />
          <FormControl.ErrorMessage>{errors.phoneNumber?.message}</FormControl.ErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.email}>
          <FormControl.Label>{'Email'}</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                caretHidden={caretHidden}
                keyboardType={'email-address'}
                autoCapitalize={'none'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                ref={ref}
              />
            )}
            name={'email'}
            defaultValue={''}
          />
          <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.password}>
          <FormControl.Label>{'Password'}</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                type={'password'}
                autoCapitalize={'none'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                ref={ref}
              />
            )}
            name={'password'}
            defaultValue={''}
          />
          <FormControl.ErrorMessage>{errors.password?.message}</FormControl.ErrorMessage>
        </FormControl>

        <Button mt={'2'} isLoading={loading} onPress={handleSubmit(handleSignUp)}>
          {'Sign up'}
        </Button>

        <HStack mt={'6'} justifyContent={'center'}>
          <Text>{'Already have an account? '}</Text>
          <LinkNavigation to={'/sign-in'}>{'Sign in'}</LinkNavigation>
        </HStack>
      </VStack>
    </ScreenBackground>
  );
}
