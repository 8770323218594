import { MaterialIcons } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLinkTo } from '@react-navigation/native';
import {
  Heading,
  VStack,
  FormControl,
  Button,
  HStack,
  Pressable,
  Input,
  FlatList,
  Box,
  Modal,
  Icon,
  Spinner,
  Text,
  ScrollView,
} from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import ScreenBackground from '~/components/ScreenBackground';
import yup from '~/lib/yup';
import { useAuth } from '~/providers/AuthProvider';

const schema = yup.object().shape({
  name: yup.string().required(),
});

export default function SelectGymScreen() {
  const mountedRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [selectedGym, setSelectedGym] = useState<{
    id: number | undefined;
    name: string | undefined;
  }>({ id: undefined, name: undefined });
  const [searching, setSearching] = useState(false);
  const [data, setData] = useState([]);
  const { searchGymByName, createMemberIfNotExists, gymId, gymName } = useAuth();
  const linkTo = useLinkTo();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<{ name: string }>({ resolver: yupResolver(schema) });

  const handleContinue = async () => {
    setLoading(true);
    await createMemberIfNotExists({
      gymId: selectedGym.id as number,
      gymName: selectedGym.name as string,
    });
    mountedRef.current && setLoading(false);
    linkTo('/timetable');
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setData([]);
  };

  const searchGym = useDebouncedCallback(async keyword => {
    setSearching(true);

    const data = await searchGymByName(keyword);
    setData(data);

    setSearching(false);
  }, 250);

  useEffect(() => {
    if (gymId && gymName) {
      setSelectedGym({ id: gymId, name: gymName });
      setValue('name', gymName);
    }
  }, [gymId, gymName, setValue]);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <ScreenBackground safeArea px={'5%'} py={'10'}>
      {/* <ScrollView> */}
      <Heading size={'lg'} fontWeight={'600'}>
        {'Welcome'}
      </Heading>
      <Heading mt={'1'} fontWeight={'medium'} size={'xs'}>
        {'Select gym to continue!'}
      </Heading>

      <VStack flex={1} space={5} mt={5}>
        <FormControl isInvalid={!!errors.name}>
          <FormControl.Label>{'Gym'}</FormControl.Label>
          <HStack space={3} justifyContent={'space-between'} alignItems={'center'}>
            <Pressable flexGrow={1} onPress={handleModalOpen}>
              <Controller
                control={control}
                render={({ field: { value } }) => <Input isReadOnly value={value} />}
                name={'name'}
                defaultValue={''}
              />
            </Pressable>
            <Button colorScheme={'secondary'} onPress={handleModalOpen} height={'100%'}>
              {'Select Gym'}
            </Button>
          </HStack>

          <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>
        </FormControl>

        {selectedGym?.name && (
          <>
            <FormControl.Label>{'Studio Waiver'}</FormControl.Label>

            <ScrollView>
              <Text>{`By accepting these terms and conditions, I acknowledge that exercising and using ${selectedGym?.name} facilities may involve a significant risk of physical harm or personal injury, including permanent disability and/or death. I understand that classes at ${selectedGym?.name} may be physically strenuous and further agree that ${selectedGym?.name} including its officers, employees, and agents shall not be liable to any person whether in contract, tort, under the statute, or otherwise for any injury, loss, damage, death or economic loss whatsoever, whether consequential, direct or indirect, caused by or connected with my participation in ${selectedGym?.name}. I agree that ${selectedGym?.name} will not accept responsibility for loss or damage to any person's belongings left unattended on the premises. ${selectedGym?.name} will not accept any refunds, cancellations, or membership transfers to others members.`}</Text>
            </ScrollView>

            <Button mt={5} isLoading={loading} onPress={handleSubmit(handleContinue)}>
              {'Accept and continue'}
            </Button>
          </>
        )}
      </VStack>

      <Modal isOpen={modalVisible} onClose={handleModalClose}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{'Select Gym'}</Modal.Header>
          <Box p={3}>
            <Input
              InputLeftElement={
                searching ? (
                  <Box ml={2}>
                    <Spinner size={'sm'} />
                  </Box>
                ) : (
                  <Icon
                    as={<MaterialIcons name={'search'} />}
                    size={5}
                    ml={'2'}
                    color={'muted.400'}
                  />
                )
              }
              flexGrow={1}
              placeholder={'Search gym name'}
              onChangeText={keyword => searchGym(keyword)}
              autoFocus
              autoCapitalize={'none'}
            />
          </Box>

          <FlatList
            data={data}
            height={'sm'}
            renderItem={({ item }) => (
              <Pressable
                borderBottomWidth={'1'}
                _dark={{
                  borderColor: 'gray.600',
                }}
                borderColor={'coolGray.200'}
                px={'4'}
                py={'2'}
                mx={2}
                onPress={() => {
                  setSelectedGym(item);
                  setValue('name', item.name);
                  handleModalClose();
                }}
              >
                {({ isHovered, isPressed }) => (
                  <Text opacity={isHovered || isPressed ? 0.5 : 1}>{item.name}</Text>
                )}
              </Pressable>
            )}
            keyExtractor={(item, idx) => `${item.id}${idx}`}
          />
        </Modal.Content>
      </Modal>
      {/* </ScrollView> */}
    </ScreenBackground>
  );
}
