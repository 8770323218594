import { Button, Divider, HStack } from 'native-base';
import React, { useState } from 'react';

import ScreenBackground from '~/components/ScreenBackground';
import ClassFlatListContainer from '~/containers/ClassFlatListContainer';
import TrainerFlatListContainer from '~/containers/TrainerFlatListContainer';

export default function TimetableScreen() {
  const [tabIndex, setTabIndex] = useState(0);

  const inactiveTabProps = {
    variant: 'outline',
    _hover: { bg: 'transparent' },
    _pressed: { bg: 'transparent' },
    _focus: { bg: 'transparent' },
    _focusVisible: { bg: 'transparent' },
  };

  return (
    <ScreenBackground p={4}>
      <HStack space={4} mb={4}>
        <Button
          borderRadius={16}
          {...(tabIndex !== 0 && inactiveTabProps)}
          onPress={() => setTabIndex(0)}
        >
          {'Classes'}
        </Button>
        <Button
          borderRadius={16}
          {...(tabIndex !== 1 && inactiveTabProps)}
          onPress={() => setTabIndex(1)}
        >
          {'Trainers'}
        </Button>
      </HStack>

      <Divider />

      {tabIndex === 0 && <ClassFlatListContainer />}
      {tabIndex === 1 && <TrainerFlatListContainer />}
    </ScreenBackground>
  );
}
