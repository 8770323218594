import { yupResolver } from '@hookform/resolvers/yup';
import { Text, Heading, VStack, FormControl, Input, Button, HStack, Toast } from 'native-base';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import LinkNavigation from '~/components/LinkNavigation';
import ScreenBackground from '~/components/ScreenBackground';
import yup from '~/lib/yup';
import { useAuth } from '~/providers/AuthProvider';

const schema = yup.object().shape({
  email: yup.string().required().email().label('Email'),
});

export default function ResetPasswordScreen() {
  // Prevent crashing on Xiaomi devices
  // https://github.com/facebook/react-native/issues/27204#issuecomment-902820636
  const [caretHidden, setCaretHidden] = useState(true);

  const [loading, setLoading] = useState(false);
  const [sentAt, setSentAt] = useState<number | null>(null);
  const [counter, setCounter] = useState<number>(5);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const cooldownPeriod = 60;

  const isResendDisabled = useCallback(
    () => !!sentAt && new Date().getTime() - cooldownPeriod * 1000 < sentAt,
    [sentAt]
  );

  const { sendResetPasswordInstructions } = useAuth();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ email: string }>({ resolver: yupResolver(schema) });

  const handleResetPassword = async (
    values: Parameters<typeof sendResetPasswordInstructions>[0]
  ) => {
    setLoading(true);

    const res = await sendResetPasswordInstructions({ ...values });

    if (res) {
      Toast.show({
        title: 'Reset password instructions sent',
        status: 'success',
      });
      setSentAt(new Date().getTime());
    }

    setLoading(false);
  };

  const countDown = useCallback(() => {
    if (!isResendDisabled()) {
      intervalRef.current && clearInterval(intervalRef.current);
      setCounter(cooldownPeriod);
    } else {
      setCounter(counter => counter - 1);
    }
  }, [isResendDisabled]);

  useEffect(() => {
    intervalRef.current = setInterval(countDown, 1000);
  }, [countDown, sentAt]);

  useEffect(() => {
    setCaretHidden(false);

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <ScreenBackground safeArea px={'5%'} py={'10'}>
      <Heading size={'lg'} fontWeight={'600'}>
        {'Reset Password'}
      </Heading>
      <Heading mt={'1'} fontWeight={'medium'} size={'xs'}>
        {'Send reset password instructions!'}
      </Heading>

      <VStack space={3} mt={'5'}>
        <FormControl isInvalid={!!errors.email}>
          <FormControl.Label>{'Email'}</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                caretHidden={caretHidden}
                keyboardType={'email-address'}
                autoCapitalize={'none'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                ref={ref}
              />
            )}
            name={'email'}
            defaultValue={''}
          />
          <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>
        </FormControl>

        <Button
          mt={'2'}
          isLoading={loading}
          onPress={handleSubmit(handleResetPassword)}
          isDisabled={isResendDisabled()}
        >
          {isResendDisabled() ? `Resend in ${counter}s` : 'Submit'}
        </Button>

        <HStack mt={'6'} justifyContent={'center'}>
          <Text>{'Already have an account? '}</Text>
          <LinkNavigation to={'/sign-in'}>{'Sign in'}</LinkNavigation>
        </HStack>
      </VStack>
    </ScreenBackground>
  );
}
