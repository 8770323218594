import { yupResolver } from '@hookform/resolvers/yup';
import { StackScreenProps } from '@react-navigation/stack';
import { Heading, VStack, FormControl, Input, Text, Button, Alert, HStack } from 'native-base';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { AuthStackParamList } from '../..';

import LinkNavigation from '~/components/LinkNavigation';
import ScreenBackground from '~/components/ScreenBackground';
import yup from '~/lib/yup';
import { useAuth } from '~/providers/AuthProvider';

const schema = yup.object().shape({
  password: yup.string().required().min(6),
  passwordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .label('Confirm password'),
});

export default function UpdatePasswordScreen({
  route,
}: StackScreenProps<AuthStackParamList, 'UpdatePassword'>) {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { updatePassword } = useAuth();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ email: string; password: string; passwordConfirmation: string }>({
    resolver: yupResolver(schema),
  });
  const { reset_password_token } = route.params;

  const handleUpdatePassword = async (values: Parameters<typeof updatePassword>[0]) => {
    setLoading(true);
    const res = await updatePassword({ reset_password_token, password: values.password });
    res && setSuccess(true);
    setLoading(false);
  };

  return (
    <ScreenBackground safeArea px={'5%'} py={'10'}>
      <Heading size={'lg'} fontWeight={'600'}>
        {'Update Password'}
      </Heading>
      <Heading mt={'1'} fontWeight={'medium'} size={'xs'}>
        {'Update your password!'}
      </Heading>

      {!success ? (
        <VStack space={3} mt={'5'}>
          <FormControl isInvalid={!!errors.password}>
            <FormControl.Label>{'Password'}</FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  type={'password'}
                  autoCapitalize={'none'}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  ref={ref}
                />
              )}
              name={'password'}
              defaultValue={''}
            />
            <FormControl.ErrorMessage>{errors.password?.message}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.passwordConfirmation}>
            <FormControl.Label>{'Confirm password'}</FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  type={'password'}
                  autoCapitalize={'none'}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  ref={ref}
                />
              )}
              name={'passwordConfirmation'}
              defaultValue={''}
            />
            <FormControl.ErrorMessage>
              {errors.passwordConfirmation?.message}
            </FormControl.ErrorMessage>
          </FormControl>

          <Button mt={'2'} isLoading={loading} onPress={handleSubmit(handleUpdatePassword)}>
            {'Submit'}
          </Button>
        </VStack>
      ) : (
        <Alert status={'success'} mt={'5'}>
          <VStack space={1} alignItems={'center'}>
            <Alert.Icon size={'md'} />
            <Text fontSize={'md'} fontWeight={'medium'} color={'darkText'}>
              {'Password updated!'}
            </Text>

            <HStack>
              <Text color={'darkText'}>{'You can now '}</Text>
              <LinkNavigation to={'/sign-in'} _text={{ color: 'darkText' }}>
                {'sign in'}
              </LinkNavigation>
              <Text color={'darkText'}>{' with your new password'}</Text>
            </HStack>
          </VStack>
        </Alert>
      )}
    </ScreenBackground>
  );
}
