import { Box, IBoxProps } from 'native-base';
import React, { FC } from 'react';

const Card: FC<IBoxProps> = ({ children, ...props }) => {
  return (
    <Box
      _light={{ bg: 'gray.50', _text: { color: 'darkText' } }}
      _dark={{ bg: 'dark.100', _text: { color: 'lightText' } }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Card;
