import 'react-native-gesture-handler';

import * as SplashScreen from 'expo-splash-screen';
import { NativeBaseProvider } from 'native-base';
import React, { useEffect } from 'react';
import { RootSiblingParent } from 'react-native-root-siblings';
import * as Sentry from 'sentry-expo';

import config from './config';
import useCachedResources from './hooks/useCachedResources';
import useColorModeManager from './hooks/useColorModeManager';
import Navigation from './navigation';
import ApolloProvider from './providers/ApolloProvider';
import AuthProvider, { useAuth } from './providers/AuthProvider';

Sentry.init({
  dsn: config.sentryDsn,
  enableInExpoDevelopment: true,
  debug: config.sentryDebug, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  environment: config.sentryEnvironment,
});

const AuthReadinessApp = ({ loading }: { loading: boolean }) => {
  const { ready } = useAuth();

  useEffect(() => {
    if (!loading && ready) {
      SplashScreen.hideAsync();
    }
  }, [loading, ready]);

  useEffect(() => {
    SplashScreen.preventAutoHideAsync();
  }, []);

  return !loading && ready ? <Navigation /> : null;
};

export default function App() {
  const { loading, theme } = useCachedResources();
  const colorModeManager = useColorModeManager();

  return (
    <RootSiblingParent>
      <NativeBaseProvider colorModeManager={colorModeManager} theme={theme}>
        <AuthProvider>
          <ApolloProvider>
            <AuthReadinessApp loading={loading} />
          </ApolloProvider>
        </AuthProvider>
      </NativeBaseProvider>
    </RootSiblingParent>
  );
}
